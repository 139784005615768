import React from 'react'
import { Link, navigate, useStaticQuery, graphql } from 'gatsby'
import styled, { css, ThemeProvider } from 'styled-components'
import { Layout, Block, MetaTags } from '../components'

import logo from '../assets/images/logo.svg'

import { container, padding, bgIcon, bgImage, hoverState} from '../styles/global';
import { grey, bronze, yellow, brown } from '../styles/colors';
import { heading } from '../styles/type';
import themes, { getThemeData } from '../styles/themes';
import { media } from '../styles/utils'

import Header from '../components/Header/Header';

class Winners extends React.Component {

	state = {

	}

	componentWillMount = () => {
		this.setState({
            data: JSON.parse(this.props.data.wordpressWinners20.acf_json),
            globalData: JSON.parse(this.props.data.wordpressGlobal.acf_json)
		})
	}

	renderGrid = () => {
        const { winners_grid } = this.state.data;
        
        //console.log('winners_grid',winners_grid);

		const items = winners_grid && winners_grid.map((item, i) => {   
            
            let cat = item.acf.category.post_title
            if(item.acf.category_override){
                cat = item.acf.category_override
            }
            let title = item.entry[0].post_title
            let description = item.acf.short_description
            if(item.override_fields){
                if(item.category.length) cat = item.category
                if(item.title.length) title = item.title
                if(item.short_description.length) description = item.short_description
            }
            let ratio = 'landscape'
            if(item.acf.hero_image.width < item.acf.hero_image.height){
                ratio = 'portrait'
            }
			return (
                <Item 
                    key={i}
                    to={`/category/${item.acf.category.post_name}`}
                >
                    <Category>{ cat }</Category>
                    <Image
						image={item.acf.hero_image.sizes.large}
                        ratio={ratio}
					/>
                   <Title>{title}</Title>
                   <Description 
                        dangerouslySetInnerHTML={{__html: description}}  
                   />
                </Item>
			)
        })   
        return (
            <Grid>{items}</Grid>
        )
	}

	render() {       
        const { data, globalData } = this.state;

        let meta_description = data.meta_description;
        if(meta_description=='') meta_description = globalData.meta_description_default

		return (
			<Layout
				// Replace with term slug 
                theme={'residential-architecture'} 
                footerImage={data.footer_bg_image && data.footer_bg_image.sizes.xlarge}
			>	
                <MetaTags
                    title={data.heading}
                    description={meta_description}
                />
				<Wrapper>
					<Header
						showNav={true}
						showSubmit={true}
						fixed={true}
						theme={{
							colour: brown,
							background: grey
						}}
					/>

                    <Heading>{data.heading}</Heading>
                    <Container>
                        {this.renderGrid()}
                    </Container>
				</Wrapper>
			</Layout>	
		)
	}
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-top: 100px;	
`
const Container = styled.div`
	${container};
	${padding};
	flex-direction: column;
	width: 100%;
	align-self: center;
	justify-content: center;
`
const Heading = styled.div`
	max-width: 100%;
	text-align: center;
	${heading};
	color: #54421A;
	margin: 190px 0;
	text-transform: uppercase;

`

const Grid = styled.div`
	display: flex;
	flex-flow: row wrap;
	width: calc(100% + 30px);
    border-top: 1px solid #54421A;
    padding-top: 53px;
`

const Item = styled(Link)`
	flex: 0 1 calc(33.3% - 30px);
	margin-right: 30px;	
	margin-bottom: 87px;

	${media.phone`
		flex: 0 1 100%;
		margin-bottom: 35px;
	`}
`

const Title = styled.div`
	font-size: 31.8px;
	line-height: 38.16px;
	font-weight: 600;
	margin-top: 38px;

	${media.phone`
		margin-top: 22px;
	`}

`

const Category = styled.div`
	font-size: 19px;
    line-height: 22px;
	text-transform: uppercase;
	letter-spacing: 0.013em;
	font-family: sofia-pro, sans-serif;
	font-weight: 300;	
	margin-bottom: 12px;
`

const Description = styled.div`
	margin-top: 18px;
	max-width: 360px;

	p {
		font-size: 23px;
		line-height: 26px;  
		letter-spacing: 0.005em;
		font-family: 'Baskerville';
		font-style: italic;
		margin-bottom: 0;
	}

	${media.phone`
		max-width: none;
	`}
`

export const Image = styled.div`
	width: 100%;
	background: ${yellow};
	background-image: url(${props => props.image});
	${bgImage}

	${props => {
		if (props.ratio == 'landscape') return css`
			padding-bottom: 83.2%;
		`

		if (props.ratio == 'portrait') return css`
            padding-bottom: 132%;  
		`
	}}

	/* ${media.phone`
		padding-bottom: 132% !important;
	`} */
`


export const query = graphql`
	query {
		wordpressWinners20 {
			acf_json	
		}	
        wordpressGlobal {
			acf_json	
		}
	}
`

export default Winners

